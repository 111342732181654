// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-tab {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.description-production-section .section-content {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description-production-section h2 {
  margin-bottom: 10px;
}

.description-production-regimes-need-row > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.description-production-regimes-need-row > div .param-input-body {
  width: 155px;
}

.description-production-equipment-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.description-equipment-alert {
  margin: 20px 0 0 0;
}

.description-file-label {
  display: flex;
  align-items: center;
}

.description-file-label svg {
  font-size: 17px;
}

.description-file-btns {
  display: flex;
  gap: 20px;
}

.description-file-csv-body {
  margin-top: 15px;
}

.description-file-csv-body .csv-file {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
}

.description-file-csv-body .fa-file-csv {
  color: green;
  font-size: 20px;
}

.description-file-btns button svg {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/DescriptionTab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".description-tab {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.description-production-section .section-content {\n  margin-top: -10px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.description-production-section h2 {\n  margin-bottom: 10px;\n}\n\n.description-production-regimes-need-row > div {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.description-production-regimes-need-row > div .param-input-body {\n  width: 155px;\n}\n\n.description-production-equipment-row {\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n\n.description-equipment-alert {\n  margin: 20px 0 0 0;\n}\n\n.description-file-label {\n  display: flex;\n  align-items: center;\n}\n\n.description-file-label svg {\n  font-size: 17px;\n}\n\n.description-file-btns {\n  display: flex;\n  gap: 20px;\n}\n\n.description-file-csv-body {\n  margin-top: 15px;\n}\n\n.description-file-csv-body .csv-file {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  font-size: 15px;\n}\n\n.description-file-csv-body .fa-file-csv {\n  color: green;\n  font-size: 20px;\n}\n\n.description-file-btns button svg {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
