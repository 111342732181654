// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1008px) {
    .horizontal-connections-section .config-form-row {
        align-items: end;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/geostorage/settings/sections/HorizontalConnectionsSection/HorizontalConnectionsSection.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media (min-width: 1008px) {\n    .horizontal-connections-section .config-form-row {\n        align-items: end;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
