// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-badge {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/IconBadge/IconBadge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".icon-badge {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    padding: 2px 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
