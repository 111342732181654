// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  height: 30px;
  user-select: none;
}

.pagination > span {
  font-size: 13px;
  color: black;
  margin: 0 7px;
}

.pagination {
  display: flex;
  gap: 2px;
}

.pagination button {
  height: 18px;
  width: 18px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--medium-grey);
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;AAClC","sourcesContent":[".pagination {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 15px;\n  height: 30px;\n  user-select: none;\n}\n\n.pagination > span {\n  font-size: 13px;\n  color: black;\n  margin: 0 7px;\n}\n\n.pagination {\n  display: flex;\n  gap: 2px;\n}\n\n.pagination button {\n  height: 18px;\n  width: 18px;\n  font-size: 9px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-color: var(--medium-grey);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
