// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-badge {
  font-weight: 300;
  font-size: 11px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/NumberBadge/NumberBadge.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".number-badge {\n  font-weight: 300;\n  font-size: 11px;\n  border-radius: 50%;\n  font-size: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 18px;\n  height: 18px;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
