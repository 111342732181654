// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heat-production-regimes-heat-wc {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heat-production-regimes-heat-wc-check {
  display: flex;
}

.heat-production-regimes-heat-wc-check > svg {
  font-size: 17px;
  margin-top: 3px;
}

.heat-production-regimes-heat-wc-inputs {
  display: flex;
  align-items: center;
  gap: 40px;
}

@media (max-width: 768px) {
  .heat-production-regimes-heat-wc-inputs {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/sections/HeatProductionSection/HeatProductionSection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;EACX;AACF","sourcesContent":[".heat-production-regimes-heat-wc {\n  margin: 25px 0;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.heat-production-regimes-heat-wc-check {\n  display: flex;\n}\n\n.heat-production-regimes-heat-wc-check > svg {\n  font-size: 17px;\n  margin-top: 3px;\n}\n\n.heat-production-regimes-heat-wc-inputs {\n  display: flex;\n  align-items: center;\n  gap: 40px;\n}\n\n@media (max-width: 768px) {\n  .heat-production-regimes-heat-wc-inputs {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
