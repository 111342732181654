// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-general-section .col-5 {
  padding-right: 0;
}

.opti-general-description {
  padding-top: 5px;
  vertical-align: middle;
  word-break: break-all;
  hyphens: auto;
}

.opti-general-badge {
  display: inline-flex;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/optimization/sections/OptiGeneralSection/OptiGeneralSection.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".opti-general-section .col-5 {\n  padding-right: 0;\n}\n\n.opti-general-description {\n  padding-top: 5px;\n  vertical-align: middle;\n  word-break: break-all;\n  hyphens: auto;\n}\n\n.opti-general-badge {\n  display: inline-flex;\n  height: 20px;\n  line-height: 20px;\n  vertical-align: middle;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
