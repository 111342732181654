// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-general-description {
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  gap: 40px;
}

.config-general-result-design {
  max-width: 50%;
  min-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/general/GeneralPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,SAAS;AACX;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".config-general-description {\n  display: flex;\n  flex-direction: row;\n  align-items: last baseline;\n  gap: 40px;\n}\n\n.config-general-result-design {\n  max-width: 50%;\n  min-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
