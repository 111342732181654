// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile {
  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid rgb(236, 237, 236);
  width: 165px;
  height: 140px;
  padding: 10px 7px 7px 7px;
  cursor: pointer;
}

.tile.active {
  border: 2px solid var(--primary-color);
}

.tile-radio.active {
  cursor: auto;
}

.tile-radio .fa-circle {
  font-size: 13px;
}

.tile-radio .fa-circle[data-prefix='fas'] {
  color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: 50%;
}

.tile-radio .fa-circle[data-prefix='far'] {
  color: var(--border-color);
  font-size: 14px;
}

.tile.disabled {
  cursor: not-allowed;
  background-color: var(--light-grey);
}

.tile > img {
  width: 56px;
  height: 55px;
  display: block;
  margin: 0 auto;
}

.tile .row {
  height: 70px;
}

.tile .row > * {
  padding: 0;
  margin-top: 10px;
}

.tile .col-3 {
  display: flex;
  justify-content: flex-end;
}

.tile-title {
  font-size: 16px;
  line-height: 21.76px;
  text-align: start;
  font-weight: 300;
  user-select: none;
}

.tile.active .tile-title {
  font-weight: 500;
}

.tile .fa-circle-question {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tile/Tile.css"],"names":[],"mappings":"AAAA;EACE,wDAAwD;EACxD,gDAAgD;EAChD,mBAAmB;EACnB,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".tile {\n  -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n  border-radius: 10px;\n  border: 1px solid rgb(236, 237, 236);\n  width: 165px;\n  height: 140px;\n  padding: 10px 7px 7px 7px;\n  cursor: pointer;\n}\n\n.tile.active {\n  border: 2px solid var(--primary-color);\n}\n\n.tile-radio.active {\n  cursor: auto;\n}\n\n.tile-radio .fa-circle {\n  font-size: 13px;\n}\n\n.tile-radio .fa-circle[data-prefix='fas'] {\n  color: var(--primary-color);\n  border: 1px solid var(--border-color);\n  border-radius: 50%;\n}\n\n.tile-radio .fa-circle[data-prefix='far'] {\n  color: var(--border-color);\n  font-size: 14px;\n}\n\n.tile.disabled {\n  cursor: not-allowed;\n  background-color: var(--light-grey);\n}\n\n.tile > img {\n  width: 56px;\n  height: 55px;\n  display: block;\n  margin: 0 auto;\n}\n\n.tile .row {\n  height: 70px;\n}\n\n.tile .row > * {\n  padding: 0;\n  margin-top: 10px;\n}\n\n.tile .col-3 {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.tile-title {\n  font-size: 16px;\n  line-height: 21.76px;\n  text-align: start;\n  font-weight: 300;\n  user-select: none;\n}\n\n.tile.active .tile-title {\n  font-weight: 500;\n}\n\n.tile .fa-circle-question {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
