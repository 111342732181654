// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pwd-input .input-group-text {
    cursor: pointer;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/PwdInput/PwdInput.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".pwd-input .input-group-text {\n    cursor: pointer;\n    width: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
