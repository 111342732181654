// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-input-body {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.projects-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  table-layout: fixed;
  background-color: white;
}

.projects-table a:hover {
  text-decoration: none;
}

.projects-table thead {
  background-color: #d5dae3;
  color: rgb(39, 39, 39);
}

.projects-table thead th {
  font-weight: 500;
  height: 45px;
  font-size: 14px;
  border: none;
  border-collapse: collapse;
  user-select: none;
}

.projects-table thead th svg {
  margin-left: 6px;
  font-size: 14px;
}

.projects-table thead th:first-of-type {
  border-top-left-radius: 5px;
}

.projects-table thead th:last-of-type {
  border-top-right-radius: 5px;
}

.projects-table thead th,
.projects-table tbody td {
  text-align: start;
  padding: 0 10px;
}

.projects-table tbody td {
  border: none;
  border-collapse: collapse;
  height: 60px;
  font-size: 14px;
}

.projects-table tbody tr:not(:last-of-type) {
  border-bottom: 1px solid rgb(227, 228, 233);
}
`, "",{"version":3,"sources":["webpack://./src/pages/projects/components/ProjectsTable/ProjectsTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,8CAA8C;EAC9C,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;;EAEE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".projects-input-body {\n  display: flex;\n  gap: 5px;\n  margin-bottom: 15px;\n  align-items: center;\n  width: 300px;\n  min-width: 300px;\n}\n\n.projects-table {\n  width: 100%;\n  border: none;\n  border-collapse: collapse;\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n  table-layout: fixed;\n  background-color: white;\n}\n\n.projects-table a:hover {\n  text-decoration: none;\n}\n\n.projects-table thead {\n  background-color: #d5dae3;\n  color: rgb(39, 39, 39);\n}\n\n.projects-table thead th {\n  font-weight: 500;\n  height: 45px;\n  font-size: 14px;\n  border: none;\n  border-collapse: collapse;\n  user-select: none;\n}\n\n.projects-table thead th svg {\n  margin-left: 6px;\n  font-size: 14px;\n}\n\n.projects-table thead th:first-of-type {\n  border-top-left-radius: 5px;\n}\n\n.projects-table thead th:last-of-type {\n  border-top-right-radius: 5px;\n}\n\n.projects-table thead th,\n.projects-table tbody td {\n  text-align: start;\n  padding: 0 10px;\n}\n\n.projects-table tbody td {\n  border: none;\n  border-collapse: collapse;\n  height: 60px;\n  font-size: 14px;\n}\n\n.projects-table tbody tr:not(:last-of-type) {\n  border-bottom: 1px solid rgb(227, 228, 233);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
