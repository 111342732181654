import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { UNIT } from '../../../../../../../../../server/constants';
import ErrorBadge from '../../../../../../../components/ErrorBadge/ErrorBadge';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import {
  formatValueWithUnit,
  isNull,
  roundUp
} from '../../../../../../../utils/data.utils';
import { getSortedExchangers } from '../../../../../../../utils/heatpump.utils';
import './PhysicalDataSection.css';

const PhysicalDataSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('noValue');
  //#endregion

  //#region [render]
  const { physicalData } = config.ConfigsSst[0].Data.pumps.regimes;
  const sortedExchangers = getSortedExchangers(physicalData);
  return (
    <Section
      title={i18n._('config.pumps.regimes.physicalData')}
      level={2}
      open
      className='physical-data-section'
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.pumps.regimes.physicalData.exchanger')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.hp')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.type')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.need')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.hpExchanger')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.connectedTo')}</th>
              <th>{i18n._('config.pumps.regimes.physicalData.hpPower')}</th>
              <th>
                {i18n._('config.pumps.regimes.physicalData.exchangerDeltaT')}
              </th>
              <th>
                {i18n._('config.pumps.regimes.physicalData.exchangerFlowRate')}
              </th>
              <th>
                {i18n._('config.pumps.regimes.physicalData.internalHeadLoss')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedExchangers.map((data, index) => (
              <tr
                key={data.exchangerType + data.hpPosition + '_' + data.hpNeed}
              >
                <td>
                  {i18n._(`hydraulicConfig.table.td.${data.exchangerType}`, {
                    position: data.hpPosition
                  })}
                </td>
                <td>{data.hpReference}</td>
                <td>{i18n._(`heatpump.type.${data.hpType}`)}</td>
                <td>{i18n._(`need.${data.hpNeed}`)}</td>
                <td>
                  {!data.hpaExchangerPosition
                    ? i18n._('noValue')
                    : i18n._(
                        `heatpump.exchangerPosition.${data.hpaExchangerPosition}`
                      )}
                </td>
                <td>
                  {i18n._(
                    `heatpump.operatingPointsSource.${data.hpOperatingPointsSource}`
                  )}
                </td>
                <td>
                  {data.hpPowerError ? (
                    <ErrorBadge
                      tooltipContent={data.hpPowerError}
                      tooltipId={'hpPower' + index}
                      portalContainer={document.body}
                    >
                      {i18n._('error')}
                    </ErrorBadge>
                  ) : (
                    formatValue(data.hpPower, UNIT.KILOWATT)
                  )}
                </td>
                <td>{formatValue(data.exchangerDeltaT, UNIT.KELVIN)}</td>
                <td>
                  {formatValue(
                    data.exchangerFlowRate,
                    UNIT.CUBIC_METER_PER_HOUR,
                    1
                  )}
                </td>
                <td>
                  {data.hpHeadLossError ? (
                    <ErrorBadge
                      tooltipContent={data.hpHeadLossError}
                      tooltipId={'headLoss' + index}
                      portalContainer={document.body}
                    >
                      {i18n._('error')}
                    </ErrorBadge>
                  ) : isNull(data.hpHeadLoss) ? (
                    i18n._('notAvailable')
                  ) : (
                    formatValue(
                      roundUp(data.hpHeadLoss, 1),
                      UNIT.WATER_COLUMN_METER,
                      1
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default PhysicalDataSection;
