// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dhw-section-check {
    align-self: center;
}

.dhw-section-type {
    margin-top: 15px;
}

@media (max-width: 1007px) {
    .dhw-section-check {
        align-self: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/settings/sections/DhwSection/DhwSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".dhw-section-check {\n    align-self: center;\n}\n\n.dhw-section-type {\n    margin-top: 15px;\n}\n\n@media (max-width: 1007px) {\n    .dhw-section-check {\n        align-self: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
