// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hydraulic-equipment-table th {
  text-align: start !important;
}

.hydraulic-equipment-table th:not(:first-of-type),
.hydraulic-equipment-table tr td:not(:first-of-type) {
  width: 13vw;
  min-width: 130px;
}

.hydraulic-equipment-table tbody tr {
  height: 45px;
}

.hydraulic-equipment-table td:first-of-type {
  font-weight: 600;
  text-align: center;
}

.hydraulic-equipment-table td > div {
  display: flex;
}

.form-check.warning {
  color: var(--danger-color);
}

.form-check.warning input {
  border-color: var(--danger-color);
}

.form-check.warning input:checked {
  background-color: var(--danger-color);
}

.form-check.warning input:focus {
  -webkit-box-shadow: 0 0 2px 1px rgba(239, 65, 26, 0.6) !important;
  box-shadow: 0 0 2px 1px rgba(239, 65, 26, 0.6) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/hydraulic/components/HydraulicEquipmentTable/HydraulicEquipmentTable.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;;EAEE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,iEAAiE;EACjE,yDAAyD;AAC3D","sourcesContent":[".hydraulic-equipment-table th {\n  text-align: start !important;\n}\n\n.hydraulic-equipment-table th:not(:first-of-type),\n.hydraulic-equipment-table tr td:not(:first-of-type) {\n  width: 13vw;\n  min-width: 130px;\n}\n\n.hydraulic-equipment-table tbody tr {\n  height: 45px;\n}\n\n.hydraulic-equipment-table td:first-of-type {\n  font-weight: 600;\n  text-align: center;\n}\n\n.hydraulic-equipment-table td > div {\n  display: flex;\n}\n\n.form-check.warning {\n  color: var(--danger-color);\n}\n\n.form-check.warning input {\n  border-color: var(--danger-color);\n}\n\n.form-check.warning input:checked {\n  background-color: var(--danger-color);\n}\n\n.form-check.warning input:focus {\n  -webkit-box-shadow: 0 0 2px 1px rgba(239, 65, 26, 0.6) !important;\n  box-shadow: 0 0 2px 1px rgba(239, 65, 26, 0.6) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
