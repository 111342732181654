// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-fallback {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.error-fallback svg {
  font-size: 80px;
  color: rgba(203, 60, 52, 0.825);
}

.error-fallback h2 {
  color: rgba(60, 60, 60, 0.825);
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorFallback/ErrorFallback.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".error-fallback {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n}\n\n.error-fallback svg {\n  font-size: 80px;\n  color: rgba(203, 60, 52, 0.825);\n}\n\n.error-fallback h2 {\n  color: rgba(60, 60, 60, 0.825);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
