// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/optimization/OptimizationForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".opti-section {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
