// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accenta-toast > :first-child {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.accenta-toast > :first-child > svg {
  margin-bottom: -2px;
  margin-right: 8px;
}

.accenta-toast > :last-child {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Toast/Toast.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;AACZ","sourcesContent":[".accenta-toast > :first-child {\n  display: flex;\n  align-items: center;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.accenta-toast > :first-child > svg {\n  margin-bottom: -2px;\n  margin-right: 8px;\n}\n\n.accenta-toast > :last-child {\n  font-size: 16px;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
