// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bloc {
  padding-bottom: 15px;
}

.bloc-children {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Bloc/Bloc.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".bloc {\n  padding-bottom: 15px;\n}\n\n.bloc-children {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  padding-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
