import { useLingui } from '@lingui/react';
import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HP_STATUS } from '../../../../../../../../../server/constants';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import {
  formatValue,
  isNull,
  roundNumber
} from '../../../../../../../utils/data.utils';
import { getTotalLevelsPerNeed } from '../../../../../../../utils/heatpump.utils';
import CapacityCellRenderer from '../../cells/CapacityCellRenderer';
import GapCellRenderer from '../../cells/GapCellRenderer';
import HpNeedsLevelsInputCellRenderer from '../../cells/HpNeedsLevelsInputCellRenderer';
import HpTotalLevelsInputCellRenderer from '../../cells/HpTotalLevelsInputCellRenderer';
import NeedLevelsInputCellRenderer from '../../cells/NeedLevelsInputCellRenderer';
import gapClassGetter from '../../cells/gapClassGetter';
import './SizingSection.css';

// on ajuste la taille du tableau en fonction des besoins sélectionnés (par 1075 px + 2px pour retirer les scroll bars)
const TABLE_WIDTH = {
  1: '1077px',
  2: '1567px',
  3: '2057px'
};

const SizingSection = ({ onFormChange, levelsErrors }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [states]
  const [rowData, setRowData] = useState([]);
  //#endregion

  //#region [refs]
  const sizingGrid = useRef();
  const totalGrid = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    setRowData(() => {
      return config.ConfigsSst[0].Data.heatpumps.list
        .filter((hp) => hp.status === HP_STATUS.MAIN)
        .map((hp) => ({
          ...hp,
          model: i18n._('config.hps.sizing.manufAndModel', {
            supplier: hp.supplier,
            model: hp.reference
          })
        }));
    });
  }, [config]);
  //#endregion

  //#region [methods]
  const getPthermCalc = (values) => {
    // le calcul vaut : puissance autorisée * nombre de levels / nombre de levels de référence (total PAC ou total besoin)
    values[0] = roundNumber(values[0], 0);
    const calc = (values[0] * values[1]) / values[2];

    if (isNull(calc) || isNaN(calc) || calc <= 0 || calc > values[0])
      return null;
    return roundNumber(calc, 0);
  };

  const getNeedsCols = () => {
    let cols = [];
    const { needs } = config.ConfigsSst[0].Data.services;
    const modalTarget = 'hps-sizing-section';
    needs.forEach((need) => {
      const needCols = {
        headerName: i18n._(`need.${need}`),
        children: [
          {
            field: `capacity.${need}.regime`,
            sortable: false,
            headerName: i18n._('config.hps.selection.regime'),
            width: 140,
            headerClass: 'flex-row-center',
            cellClass: 'flex-row-center',
            cellRenderer: ({ data, value }) =>
              data.needs.includes(need) ? value : null
          },
          {
            field: `capacity.${need}.ptherm`,
            headerName: i18n._('config.hps.selection.ptherm.available'),
            width: 120,
            sortable: false,
            headerClass: 'flex-row-center',
            cellClass: 'flex-row-center',
            cellRenderer: ({ data, value }) => {
              return data.needs.includes(need) ? (
                <CapacityCellRenderer
                  data={data}
                  value={value}
                  need={need}
                  modalTarget={modalTarget}
                />
              ) : null;
            }
          },
          {
            field: `capacity.${need}.levels`,
            headerName: i18n._('config.hps.selection.levels'),
            sortable: false,
            width: 90,
            headerClass: 'flex-row-center',
            cellClass: 'cell-input',
            cellRenderer: HpNeedsLevelsInputCellRenderer,
            cellRendererParams: { need, onFormChange }
          },
          {
            headerName: i18n._('config.hps.selection.ptherm.allowed'),
            width: 140,
            headerClass: 'flex-row-center',
            cellClass: 'flex-row-center',
            wrapText: true,
            autoHeight: true,
            sortable: false,
            cellRenderer: ({ data }) => {
              const hpAllowedPtherm = getPthermCalc([
                data.capacity[need]?.ptherm,
                data.capacity[need]?.levels,
                data.levels
              ]);
              const error =
                levelsErrors[need]?.[data.position] || !hpAllowedPtherm;
              return !data.needs.includes(need)
                ? null
                : data.levels === 0
                ? roundNumber(data.capacity[need]?.ptherm, 0) + ' kW'
                : error
                ? i18n._('error')
                : hpAllowedPtherm + ' kW';
            }
          }
        ]
      };
      cols = cols.concat([needCols]);
    });
    return cols;
  };

  const getDescriptionTotalCols = () => {
    return [
      {
        field: 'descTotal.label',
        width: 90,
        colSpan: () => 3,
        cellClass: (data) => {
          return data.data.descTotal && ['total-value-cell', 'flex-row-center'];
        }
      },
      {
        width: 180
      },
      {
        width: 100
      },
      {
        field: 'descTotal.totalComp',
        width: 125,
        cellClass: (data) => {
          return data.data.descTotal && ['total-value-cell', 'flex-row-center'];
        }
      },
      {
        field: 'descTotal.totalLevels',
        width: 90,
        cellClass: (data) => {
          return data.data.descTotal && ['total-value-cell', 'flex-row-center'];
        }
      }
    ];
  };

  const getNeedsTotalCols = () => {
    let cols = [];
    const { needs } = config.ConfigsSst[0].Data.services;
    needs.forEach((need) => {
      // on vérifie si une erreur vient des levels PACs ou des levels total usage
      const gapError = levelsErrors[need];

      const totalRegimeCol = {
        field: `${[need]}.label`,
        cellClass: ({ data, value }) => {
          return gapClassGetter(data, value, need, gapError);
        },
        width: 140,
        colSpan: ({ data }) =>
          data[need]?.building ? 3 : data[need]?.gap ? 4 : 1,
        cellRenderer: GapCellRenderer,
        cellRendererParams: { need, gapError }
      };
      const totalPCol = {
        field: `${[need]}.totalPtherm`,
        width: 120,
        colSpan: ({ data }) => (data[need]?.building ? 2 : 1),
        cellClass: ['total-value-cell', 'flex-row-center'],
        cellRenderer: ({ data, value }) =>
          !data[need]?.building ? formatValue(value, 0) + ' kW' : null
      };
      const levelCol = {
        field: `${[need]}.needLevels`,
        width: 90,
        cellClass: 'total-cell-input',
        cellRenderer: NeedLevelsInputCellRenderer,
        cellRendererParams: {
          onFormChange,
          need
        }
      };
      const allowedPCol = {
        field: `${need}.allowedTotalPtherm`,
        width: 140,
        cellClass: ['total-value-cell', 'flex-row-center'],
        cellRenderer: ({ value }) => value
      };
      cols = cols.concat([totalRegimeCol, totalPCol, levelCol, allowedPCol]);
    });
    return cols;
  };
  //#endregion

  //#region [memos]
  const columnDefs = useMemo(() => {
    const needsCols = getNeedsCols();
    return [
      {
        headerName: i18n._('config.hps.selection.description'),
        children: [
          {
            field: 'position',
            sortable: false,
            headerName: i18n._('config.hps.position'),
            width: 90,
            headerClass: 'flex-row-center',
            cellClass: 'flex-row-center'
          },
          {
            field: 'reference',
            sortable: false,
            headerName: i18n._('config.hps.sizing.model.header'),
            width: 180,
            headerClass: 'pl-1',
            cellClass: 'pl-1',
            tooltipField: 'reference'
          },
          {
            field: 'type',
            sortable: false,
            headerName: i18n._('config.hps.type'),
            width: 100,
            headerClass: 'flex-row-center',
            cellClass: 'flex-row-center',
            cellRenderer: (value) => i18n._(`heatpump.type.${value.data.type}`)
          },
          {
            field: 'compressors',
            sortable: false,
            headerName: i18n._('config.hps.compressors'),
            width: 125,
            headerClass: 'flex-row-center',
            cellRenderer: (value) => {
              const { compressors } = value.data;
              return compressors === 0 ? i18n._('unknown') : compressors;
            },
            cellClass: 'flex-row-center'
          },
          {
            field: 'levels',
            sortable: false,
            headerName: i18n._('config.hps.levels'),
            width: 90,
            headerClass: 'flex-row-center',
            cellClass: 'cell-input',
            cellRenderer: HpTotalLevelsInputCellRenderer,
            cellRendererParams: { onFormChange }
          }
        ]
      },
      ...needsCols
    ];
  }, [levelsErrors]);

  // Colonnes du tableau des totaux
  const totalColumnDefs = useMemo(() => {
    const totalDescCols = getDescriptionTotalCols();
    const totalCols = getNeedsTotalCols();
    return [...totalDescCols, ...totalCols];
  }, [levelsErrors]);

  // Lignes du tableau des totaux
  const totalRowData = useMemo(() => {
    // on set les données de la partie description
    let totalCompressors = 0;
    let totalLevels = 0;
    rowData.forEach((hp) => {
      totalCompressors += hp.compressors;
      totalLevels += hp.levels;
    });
    let rows = [
      {
        descTotal: {
          label: i18n._('selectedHps.descTotal.hp'),
          totalComp: totalCompressors,
          totalLevels: totalLevels
        }
      },
      {},
      {}
    ];
    // on set les données de la partie des besoins (chaud, froid, ecs)
    const { services, heatpumps } = config.ConfigsSst[0].Data;

    services.needs.forEach((need) => {
      // Total des puissances disponibles des PAC, par besoin
      const needTotalPtherm = rowData
        .filter((hp) => hp.needs.includes(need) && !hp.capacity[need].error)
        .reduce((acc, hp) => {
          acc += hp.capacity[need].ptherm;
          return acc;
        }, 0);

      // total de la puissance autorisée par besoin
      const needTotalAllowedPtherm = getPthermCalc([
        needTotalPtherm,
        heatpumps.levels[need],
        getTotalLevelsPerNeed(heatpumps.list, need)
      ]);

      // Total PAC
      rows[0][need] = {
        label: i18n._('selectedHps.total.hp'),
        totalPtherm: needTotalPtherm,
        needLevels: heatpumps.levels[need], // total des levels chaud, froid, ecs
        allowedTotalPtherm:
          levelsErrors[need] || !needTotalAllowedPtherm
            ? i18n._('error')
            : needTotalAllowedPtherm + ' kW'
      };

      // Total bâtiment
      rows[1][need] = {
        label: i18n._('selectedHps.total.building'),
        allowedTotalPtherm: services[need].pMax,
        building: true
      };

      // écart (gap)
      rows[2][need] = {
        // pas de titre sur cette cellule, on passe directement le calcul
        label:
          (needTotalAllowedPtherm - services[need].pMax) / services[need].pMax,
        gap: true
      };
    });
    return rows;
  }, [config.ConfigsSst[0].Data.services.needs, rowData]);
  //#endregion

  //#region [render]
  const { needs } = config.ConfigsSst[0].Data.services;
  return (
    <Section
      title={i18n._('config.hps.selection.sizing')}
      level={2}
      open
      className='hps-sizing-section'
    >
      <div
        className='hps-sizing-grids-wrapper'
        style={{ maxWidth: TABLE_WIDTH[needs.length] }}
      >
        <div
          className='ag-theme-alpine hps-sizing-wrapper'
          style={{ height: 82 + 45 * rowData.length }}
        >
          <AgGridReact
            ref={sizingGrid}
            rowData={rowData}
            columnDefs={columnDefs}
            headerHeight={40}
            rowHeight={45}
            defaultColDef={{
              editable: false,
              sortable: true,
              resizable: false
            }}
            alignedGrids={totalGrid.current ? [totalGrid.current] : undefined}
            suppressCellFocus
            suppressMovableColumns
            suppressHorizontalScroll
          />
        </div>
        <div className='hps-sizing-total-wrapper'>
          <AgGridReact
            ref={totalGrid}
            rowData={totalRowData}
            columnDefs={totalColumnDefs}
            headerHeight={0}
            rowHeight={45}
            domLayout={'autoHeight'}
            alignedGrids={sizingGrid.current ? [sizingGrid.current] : undefined}
            suppressCellFocus
          />
        </div>
      </div>
    </Section>
  );
  //#endregion
};

export default SizingSection;
