import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { UNIT } from '../../../../../../../../server/constants';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import {
  formatValueWithUnit,
  isNull,
  roundUp
} from '../../../../../../utils/data.utils';
import { getSortedExchangers } from '../../../../../../utils/heatpump.utils';

const DestinationsHeadLossSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast, openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('notAvailable');

  const handleShowHeadLossDataClick = (exchanger) => {
    try {
      const data = { ...exchanger };
      delete data.hpHeadLoss;
      delete data.pipingHeadLoss;
      delete data.collectorsHeadLoss;

      const filename = `PdC Destinations : ${exchanger.exchangerType}${
        exchanger.hpPosition
      } ${exchanger.hpReference} ${i18n._(`need.${exchanger.hpNeed}`)}`;
      openInfoModal(
        filename,
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { exchangersHeadLossData } = config.ConfigsSst[0].Data.pumps;
  const sortedExchangers = getSortedExchangers(exchangersHeadLossData);
  return (
    <Section
      title={i18n._('config.pumps.headLoss.destinationsHeadLoss')}
      level={2}
      open
    >
      <table className='custom-table'>
        <thead>
          <tr>
            <th>{i18n._('config.pumps.headLoss.pumps')}</th>
            <th>{i18n._('config.pumps.headLoss.hp')}</th>
            <th>{i18n._('config.pumps.headLoss.need')}</th>
            <th>{i18n._('config.pumps.headLoss.connectedTo')}</th>
            <th>{i18n._('config.pumps.headLoss.flowRate')}</th>
            <th>{i18n._('config.pumps.headLoss.total')}</th>
            <th style={{ width: '100px', minWidth: '100px' }}></th>
          </tr>
        </thead>
        <tbody>
          {sortedExchangers.map((exchanger, index) => (
            <tr key={'exchanger_destinations_headloss' + index}>
              <td>
                {i18n._(`hydraulicConfig.table.td.${exchanger.exchangerType}`, {
                  position: exchanger.hpPosition
                })}
              </td>
              <td>{exchanger.hpReference}</td>
              <td>{i18n._(`need.${exchanger.hpNeed}`)}</td>
              <td>
                {i18n._(
                  `heatpump.operatingPointsSource.${exchanger.hpOperatingPointsSource}`
                )}
              </td>
              <td className='col-flowRate'>
                {formatValue(
                  exchanger.exchangerFlowRate,
                  UNIT.CUBIC_METER_PER_HOUR,
                  1
                )}
              </td>
              <td className='col-headLoss'>
                {formatValue(
                  roundUp(exchanger.destinationsHeadLoss, 1),
                  UNIT.WATER_COLUMN_METER,
                  1
                )}
              </td>
              <td style={{ width: '100px', minWidth: '100px' }}>
                <Button
                  className='pumps-calculations-dl-btn'
                  variant='outline-secondary'
                  onClick={() => handleShowHeadLossDataClick(exchanger)}
                >
                  <FontAwesomeIcon icon='file-code' />
                  JSON
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
  );
  //#endregion
};

export default DestinationsHeadLossSection;
