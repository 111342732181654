import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './EditableCounter.css';

const EditableCounter = ({ value, increment, decrement, valueClassName }) => (
  <>
    <FontAwesomeIcon
      icon='circle-minus'
      onClick={() => decrement(value - 1)}
      className={value === 0 ? 'disabled' : ''}
    />
    <span className={`counter-value ${valueClassName}`}>{value}</span>
    <FontAwesomeIcon icon='circle-plus' onClick={() => increment(value + 1)} />
  </>
);

export default EditableCounter;
