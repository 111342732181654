import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import reactSelectStyles from '../../styles/react-select.style';
import './HPSelect.css';

const HPSelect = ({ onHPChange, heatpumps, selectedHP }) => {
  //#region [memos]
  const groupedOptions = useMemo(() => {
    return !heatpumps
      ? []
      : Object.entries(heatpumps)
          .map(([supplier, group]) => ({
            label: supplier,
            options: group
              .map((hp) => ({
                value: hp.fileId,
                label: hp.filename
              }))
              .sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
              )
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          );
  }, [heatpumps]);

  const selectedOption = useMemo(() => {
    try {
      const group = groupedOptions.find(
        (groups) => groups.label === selectedHP.supplier
      );
      return group.options.find((option) => option.value === selectedHP.fileId);
    } catch (err) {
      console.error(err);
      return null;
    }
  }, [groupedOptions, selectedHP]);
  //#endregion

  //#region [select components]
  const SingleValue = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.SingleValue {...props}>
          <span title={props.data.label} className='ellipsis'>
            {props.data.label}
          </span>
        </components.SingleValue>
      );
    }, [props.data.label]);
    return content;
  };

  const GroupHeading = ({ ...props }) => (
    <div className='group-header'>
      <components.GroupHeading {...props} />
    </div>
  );

  const Option = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.Option {...props}>
          <div
            className='result-option'
            onClick={async () =>
              await onHPChange({
                fileId: props.data.value,
                filename: props.data.label
              })
            }
          >
            <span
              className='result-option-label ellipsis'
              title={props.data.label}
            >
              {props.data.label}
            </span>
          </div>
        </components.Option>
      );
    }, [props.data.label, props.isSelected, props.isFocused]);
    return content;
  };
  //#endregion

  //#region [render]
  return (
    <Select
      value={selectedOption}
      options={groupedOptions}
      components={{ SingleValue, GroupHeading, Option }}
      styles={reactSelectStyles}
      isSearchable
      className='hp-select'
    />
  );
  //#endregion
};

export default HPSelect;
