// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compute-page {
  width: 100%;
  display: flex;
  gap: 2%;
}

@media (max-width: 1007px) {
  .compute-page {
    flex-direction: column;
    gap: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputePage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,OAAO;AACT;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;AACF","sourcesContent":[".compute-page {\n  width: 100%;\n  display: flex;\n  gap: 2%;\n}\n\n@media (max-width: 1007px) {\n  .compute-page {\n    flex-direction: column;\n    gap: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
