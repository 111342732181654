// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compute-forms {
  width: 50%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgb(236, 237, 236);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.compute-form-nav {
  display: flex;
  gap: 10px;
}

.compute-info-icon {
  font-size: 17px;
}

.max-modal p {
  margin: 0;
  padding: 0;
}

.max-modal > p:nth-of-type(1),
.max-modal > p:nth-of-type(3) {
  font-size: 15px;
}

.max-modal > p:nth-of-type(2) {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.max-modal span {
  font-weight: 600;
  font-size: 15px;
}

@media (max-width: 1007px) {
  .compute-forms {
    width: 100%;
    order: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/ComputeForm.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,oCAAoC;EACpC,sDAAsD;EACtD,8CAA8C;EAC9C,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;IACX,QAAQ;EACV;AACF","sourcesContent":[".compute-forms {\n  width: 50%;\n  padding: 15px;\n  border-radius: 5px;\n  border: 1px solid rgb(236, 237, 236);\n  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);\n  height: fit-content;\n}\n\n.compute-form-nav {\n  display: flex;\n  gap: 10px;\n}\n\n.compute-info-icon {\n  font-size: 17px;\n}\n\n.max-modal p {\n  margin: 0;\n  padding: 0;\n}\n\n.max-modal > p:nth-of-type(1),\n.max-modal > p:nth-of-type(3) {\n  font-size: 15px;\n}\n\n.max-modal > p:nth-of-type(2) {\n  font-size: 16px;\n  font-weight: 600;\n  text-align: center;\n}\n\n.max-modal span {\n  font-weight: 600;\n  font-size: 15px;\n}\n\n@media (max-width: 1007px) {\n  .compute-forms {\n    width: 100%;\n    order: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
