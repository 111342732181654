// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upper-modules-table th {
    font-weight: bold;
}

.upper-modules-table tbody>tr:first-of-type {
    font-weight: bold;
    background-color: var(--primary-color);
    color: white;
    font-size: 14px;
}

.upper-modules-table td,
.upper-modules-table th{
    min-width: 120px;
    padding: 12px;
    border: 1px solid var(--light-grey);
}

.upper-modules-table th {
    height: 45px;
    text-align: center;
    border-right: 1px solid var(--dark-grey);
}

.upper-modules-table th:last-of-type {
    border-right: none;
}

.upper-modules-table td {
    max-width: 250px;
}

.upper-modules-table .nominalDiameter-warning {
    display: flex;
    flex-direction: row;
    align-Items: center;
    gap: 10px;
}
  
`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/hydraulic/sections/UpperModulesSection/UpperModulesSection.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,sCAAsC;IACtC,YAAY;IACZ,eAAe;AACnB;;AAEA;;IAEI,gBAAgB;IAChB,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".upper-modules-table th {\n    font-weight: bold;\n}\n\n.upper-modules-table tbody>tr:first-of-type {\n    font-weight: bold;\n    background-color: var(--primary-color);\n    color: white;\n    font-size: 14px;\n}\n\n.upper-modules-table td,\n.upper-modules-table th{\n    min-width: 120px;\n    padding: 12px;\n    border: 1px solid var(--light-grey);\n}\n\n.upper-modules-table th {\n    height: 45px;\n    text-align: center;\n    border-right: 1px solid var(--dark-grey);\n}\n\n.upper-modules-table th:last-of-type {\n    border-right: none;\n}\n\n.upper-modules-table td {\n    max-width: 250px;\n}\n\n.upper-modules-table .nominalDiameter-warning {\n    display: flex;\n    flex-direction: row;\n    align-Items: center;\n    gap: 10px;\n}\n  \n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
