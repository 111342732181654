import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { UNIT } from '../../../../../../../../../../server/constants';
import EllipsisText from '../../../../../../../../components/EllipsisText/EllipsisText';
import IconAlert from '../../../../../../../../components/IconAlert/IconAlert';
import {
  getTableValue,
  isArrNullOrEmpty,
  isNull
} from '../../../../../../../../utils/data.utils';

const EquipmentRow = ({ rows, modulesRows = false }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const getModuleError = (mod) => {
    if (!mod) {
      return i18n._('config.equipmentList.warning.noModule');
    } else if (isArrNullOrEmpty(mod.equipment)) {
      return i18n._('config.equipmentList.warning.noEquipment');
    } else {
      return;
    }
  };
  //endregion

  //#region [render]
  if (isArrNullOrEmpty(rows)) return;
  if (!modulesRows) rows.sort((a, b) => a.name?.localeCompare(b.name));
  return (
    <Fragment>
      {rows.map((equipment, index) => {
        // l'équipment a des prix détaillés
        if (equipment.prices) {
          const moduleError = getModuleError(equipment);
          const {
            total,
            hydraulicAndStructure,
            factoryInsulation,
            siteInsulation,
            equipmentConfig
          } = equipment.prices;
          return (
            <tr key={`${index}_${equipment.name}`} className='second-level-row'>
              <td />
              <td>
                <IconAlert
                  showIcon={moduleError}
                  iconSize='small'
                  tooltipId={'equipment' + index}
                  tooltipMessage={moduleError}
                  reversed={true}
                >
                  <EllipsisText
                    text={
                      equipment.name ??
                      i18n._('config.hps.lowerModule.notFound')
                    }
                    maxLength={30}
                    tooltipId={`${index}_${equipment.name}`}
                  />
                </IconAlert>
              </td>
              <td></td>
              <td>{getTableValue(i18n, total, UNIT.EURO, 0)}</td>
              <td />
              <td>
                {!isNull(hydraulicAndStructure) &&
                  getTableValue(i18n, hydraulicAndStructure, UNIT.EURO, 0)}
              </td>
              <td>
                {!isNull(factoryInsulation) &&
                  getTableValue(i18n, factoryInsulation, UNIT.EURO, 0)}
              </td>
              <td>
                {!isNull(siteInsulation) &&
                  getTableValue(i18n, siteInsulation, UNIT.EURO, 0)}
              </td>
              <td>
                {!isNull(equipmentConfig) &&
                  getTableValue(i18n, equipmentConfig, UNIT.EURO, 0)}
              </td>
            </tr>
          );
        } else {
          // l'équipment a des prix simplifiés
          return (
            <tr
              key={`${index}_${equipment.name ?? equipment.reference}`}
              className='second-level-row'
            >
              <td />
              <td>
                <EllipsisText
                  text={equipment.name ?? equipment.reference}
                  maxLength={30}
                  tooltipId={`${index}_${equipment.name}_reference`}
                />
              </td>
              <td>
                {equipment.linkedModule && (
                  <EllipsisText
                    text={equipment.linkedModule}
                    maxLength={30}
                    tooltipId={`${index}_${equipment.name}_module`}
                  />
                )}
              </td>
              <td>{getTableValue(i18n, equipment.price, UNIT.EURO, 0)}</td>
              <td>
                {!modulesRows &&
                  getTableValue(i18n, equipment.price, UNIT.EURO, 0)}
              </td>
              <td colSpan={6}></td>
            </tr>
          );
        }
      })}
    </Fragment>
  );
  //endregion
};
export default EquipmentRow;
