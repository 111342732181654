// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.substation-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}

.substation-section .col {
  padding-left: 0;
}

.substation-section .alert {
  margin-bottom: 0;
}

@media (max-width: 1007px) {
  .substation-section {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/DescriptionsTabs/DescriptionTab/blocs/SubstationBloc/SubstationTab/SubstationTab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".substation-section {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 60%;\n}\n\n.substation-section .col {\n  padding-left: 0;\n}\n\n.substation-section .alert {\n  margin-bottom: 0;\n}\n\n@media (max-width: 1007px) {\n  .substation-section {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
