// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-modal .modal-header {
  color: var(--danger-color);
  font-size: 18px;
}

.delete-modal .modal-header svg {
  margin-left: 8px;
  cursor: auto;
}

.delete-modal .result-page-option {
  height: 45px;
  border-bottom: 1px solid rgb(232, 232, 232);
  padding-left: 10px;
  cursor: pointer;
}

.delete-modal .result-page-option:hover {
  background-color: #f6f6f6;
}

.delete-modal .result-page-option input[type='checkbox'] {
  cursor: pointer;
}

.delete-modal .result-page-option input[type='checkbox']:checked {
  background-color: var(--danger-color) !important;
  border-color: var(--danger-color) !important;
}

.delete-modal .result-page-option input[type='checkbox']:focus {
  border-color: var(--danger-color) !important;
  box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;
}

.delete-warning {
  font-size: 15px;
  color: #363636;
}

.delete-list {
  height: calc(100vh - 300px);
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/components/DeleteModal/DeleteModal.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,2CAA2C;EAC3C,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gDAAgD;EAChD,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;EAC5C,yDAAyD;AAC3D;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,8CAA8C;AAChD","sourcesContent":[".delete-modal .modal-header {\n  color: var(--danger-color);\n  font-size: 18px;\n}\n\n.delete-modal .modal-header svg {\n  margin-left: 8px;\n  cursor: auto;\n}\n\n.delete-modal .result-page-option {\n  height: 45px;\n  border-bottom: 1px solid rgb(232, 232, 232);\n  padding-left: 10px;\n  cursor: pointer;\n}\n\n.delete-modal .result-page-option:hover {\n  background-color: #f6f6f6;\n}\n\n.delete-modal .result-page-option input[type='checkbox'] {\n  cursor: pointer;\n}\n\n.delete-modal .result-page-option input[type='checkbox']:checked {\n  background-color: var(--danger-color) !important;\n  border-color: var(--danger-color) !important;\n}\n\n.delete-modal .result-page-option input[type='checkbox']:focus {\n  border-color: var(--danger-color) !important;\n  box-shadow: 0 0 2px 2px rgba(193, 43, 43, 0.2) !important;\n}\n\n.delete-warning {\n  font-size: 15px;\n  color: #363636;\n}\n\n.delete-list {\n  height: calc(100vh - 300px);\n  overflow: auto;\n  border-radius: 5px;\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
