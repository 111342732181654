import { useLingui } from '@lingui/react';
import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import EditableCounter from '../EditableCounter/EditableCounter';
import './FittingsModal.css';

const FittingsModal = ({
  isOpen,
  defaultFittings,
  initialFittings,
  defaultKvs,
  initialKvs,
  title,
  subtitle,
  onClose,
  onValidate,
  isModular
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [fittings, setFittings] = useState(initialFittings);
  const [kvs, setKvs] = useState(initialKvs);
  //#endregion

  //#region [methods]
  const handleCounterFittingValueChange = (key, value) => {
    if (value < 0) return;
    setFittings({ ...fittings, [key]: value });
  };
  const handleCounterKvValueChange = (index, value) => {
    if (value < 0) return;
    setKvs([...kvs.map((kv, i) => (i === index ? { ...kv, kv: value } : kv))]);
  };
  //#endregion

  //#region [render]
  return (
    <Modal show={isOpen} onHide={onClose} centered className='fitting-modal'>
      <Modal.Header closeButton>
        <div className='fitting-modal-header'>
          <h1>{title || i18n._('fittingsModal.title')}</h1>
          {subtitle && <p>{subtitle}</p>}
          {isModular && i18n._('fittingsModal.modular.description')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='fitting-modal-body'>
          {/* Singularités standards */}
          {isModular && (
            <div className='fitting-modal-column'>
              <h3>{i18n._('fittingsModal.regularFittings')}</h3>
              <div className='fitting-modal-kvGrid'>
                {!kvs || isArrNullOrEmpty(kvs)
                  ? i18n._('fittingsModal.noKv')
                  : kvs.map((kv, index) => {
                      const kvId = 'kv_' + index;
                      const edited = kv.kv !== defaultKvs[index].kv;
                      return (
                        <Fragment key={kvId}>
                          <span
                            className={`kv-modal-key ${edited && 'kv-edited'}`}
                            data-tooltip-id={'tooltip_' + kvId}
                            data-tooltip-content={i18n._(
                              'geostorage.kv.edited'
                            )}
                          >
                            {kv.name}
                          </span>
                          <EditableCounter
                            decrement={(newValue) =>
                              handleCounterKvValueChange(index, newValue)
                            }
                            increment={(newValue) =>
                              handleCounterKvValueChange(index, newValue)
                            }
                            value={kv.kv}
                            valueClassName={`kv-modal-counter-value ${
                              edited && 'kv-edited'
                            }`}
                          />
                          {edited && (
                            <Tooltip
                              id={'tooltip_' + kvId}
                              place='left'
                              opacity={1}
                            />
                          )}
                        </Fragment>
                      );
                    })}
              </div>
            </div>
          )}
          {/* Singularités optionnelles */}
          <div className='fitting-modal-column'>
            {isModular && <h3>{i18n._('fittingsModal.optionnalFittings')}</h3>}
            <div className='fitting-modal-fittingsGrid'>
              {Object.entries(fittings).map(([key, value]) => {
                const fittingId = 'fitting_' + key;
                const edited = value !== defaultFittings[key];
                return (
                  <Fragment key={fittingId}>
                    <span
                      className={`fitting-modal-key ${
                        edited && 'fitting-edited'
                      }`}
                      data-tooltip-id={'tooltip_' + fittingId}
                      data-tooltip-content={i18n._('geostorage.fitting.edited')}
                    >
                      {i18n._(`geostorage.fitting.${key}`)}
                    </span>
                    <EditableCounter
                      decrement={(newValue) =>
                        handleCounterFittingValueChange(key, newValue)
                      }
                      increment={(newValue) =>
                        handleCounterFittingValueChange(key, newValue)
                      }
                      value={value}
                      valueClassName={`fitting-modal-counter-value ${
                        edited && 'fitting-edited'
                      }`}
                    />
                    {edited && (
                      <Tooltip
                        id={'tooltip_' + fittingId}
                        place='left'
                        opacity={1}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-secondary'
          onClick={() =>
            setFittings(() => defaultFittings) & setKvs(() => defaultKvs)
          }
        >
          {i18n._('fittingsModal.reset')}
        </Button>
        <Button onClick={async () => await onValidate(fittings, kvs)}>
          {i18n._('validate')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default FittingsModal;
