// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-btn {
    width: fit-content;
    align-self: center;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.3s;
}

.save-btn.saved,
.save-btn.saved:focus,
.save-btn.saved:disabled {
    color: var(--success-color);
    background-color: white;
    border: 1px solid var(--success-color);
}

.save-btn>svg {
    margin-left: 7px;
    transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/components/SaveButton/SaveButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;;IAGI,2BAA2B;IAC3B,uBAAuB;IACvB,sCAAsC;AAC1C;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".save-btn {\n    width: fit-content;\n    align-self: center;\n    margin-top: 15px;\n    margin-bottom: 15px;\n    transition: 0.3s;\n}\n\n.save-btn.saved,\n.save-btn.saved:focus,\n.save-btn.saved:disabled {\n    color: var(--success-color);\n    background-color: white;\n    border: 1px solid var(--success-color);\n}\n\n.save-btn>svg {\n    margin-left: 7px;\n    transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
