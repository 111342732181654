import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  EQUIPMENT_FAMILY,
  SUPPLIER,
  UNIT
} from '../../../../../../../../../server/constants';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import {
  getTableValue,
  isArrNullOrEmpty,
  isNull
} from '../../../../../../../utils/data.utils';
import EquipmentRows from './components/EquipmentRows';

// liste des fournisseurs par défaut
const SUPPLIERS_LIST = {
  [SUPPLIER.HYDROTHERM]: {
    equipment: [],
    supplierTotalPrices: {
      equipmentConfig: 0,
      factoryInsulation: 0,
      hydraulicAndStructure: 0,
      manufacturingTotal: 0,
      moduleEquipmentTotal: 0,
      siteInsulation: 0,
      total: 0
    }
  },
  [SUPPLIER.UNKNOWN]: { equipment: [], supplierTotalPrice: 0 }
};

const SortBySupplierList = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [states]
  const [suppliersRows, setSuppliersRows] = useState(null);
  const [equipmentRows, setEquipmentRows] = useState([]);
  //endregion

  //#region [effects]
  useEffect(() => {
    // on parcourt tous les équipments pour les ajouter à la liste des fournisseurs
    const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
    let suppliersList = cloneDeep(SUPPLIERS_LIST);

    heatpumps.list.forEach((hp) => {
      // PAC
      suppliersList = addEquipmentToSuppliersList(
        suppliersList,
        hp,
        hp.manufacturer
      );
      // modules de la PAC
      [hp?.lowerModule, hp?.upperModule].forEach(
        (hpModule) =>
          (suppliersList = addModuleToSuppliersList(suppliersList, hpModule))
      );
    });
    // modules du géostockage
    if (geostorage.lowerModules && geostorage.upperModules) {
      [
        geostorage.lowerModules.capture,
        geostorage.lowerModules.injection,
        geostorage.upperModules.injection
      ].forEach(
        (geoModule) =>
          (suppliersList = addModuleToSuppliersList(suppliersList, geoModule))
      );
    }
    // on ordonne la liste par ordre alphabétique (nom du fournisseur)
    const sortedSuppliersList = Object.keys(suppliersList)
      .sort()
      .reduce((obj, key) => {
        obj[key] = suppliersList[key];
        return obj;
      }, {});

    // on n'affiche pas la section "Fournisseur inconnu" si elle est vide
    if (isArrNullOrEmpty(sortedSuppliersList[SUPPLIER.UNKNOWN].equipment))
      delete sortedSuppliersList[SUPPLIER.UNKNOWN];

    setSuppliersRows({ ...sortedSuppliersList });
  }, []);

  useEffect(() => {
    if (!suppliersRows) return;
    setEquipmentRows([...Object.keys(suppliersRows)]);
  }, [suppliersRows]);
  //endregion

  //#region [methods]
  const addEquipmentToSuppliersList = (suppliersList, equipment, supplier) => {
    if (isNull(supplier) || supplier === '') {
      // fournisseur non connu
      suppliersList[SUPPLIER.UNKNOWN].equipment.push(equipment);
      suppliersList[SUPPLIER.UNKNOWN].supplierTotalPrice += equipment.price;
    } else if (!suppliersList[supplier]) {
      // le fournisseur n'existe pas, on le créé avec le premier équipement
      suppliersList[supplier] = {
        equipment: [equipment],
        supplierTotalPrice: equipment.price
      };
    } else {
      // le fournisseur existe, on ajoute l'équipement
      suppliersList[supplier].equipment.push(equipment);
      suppliersList[supplier].supplierTotalPrice += equipment.price;
    }
    return suppliersList;
  };

  const addModuleToSuppliersList = (suppliersList, moduleToAdd) => {
    if (moduleToAdd) {
      suppliersList[SUPPLIER.HYDROTHERM].equipment.push(moduleToAdd);

      // si le module a un prix simple, on l'ajoute au total
      if (moduleToAdd.price) {
        suppliersList[SUPPLIER.HYDROTHERM].supplierTotalPrices.total +=
          moduleToAdd.price;
      } else {
        // le module a des prix détaillés
        Object.entries(moduleToAdd.prices).forEach(
          ([type, price]) =>
            (suppliersList[SUPPLIER.HYDROTHERM].supplierTotalPrices[type] +=
              price)
        );
      }
      // on ajoute à liste les équipements de chaque module
      if (moduleToAdd.equipment) {
        moduleToAdd.equipment
          .filter((equipment) =>
            equipment.family
              ? equipment.family === EQUIPMENT_FAMILY.CLIMATIC
              : equipment
          )
          .forEach((equipment) => {
            // on ajoute le nom du module lié pour l'afficher dans le tableau
            const equipmentCopy = {
              ...equipment,
              linkedModule: moduleToAdd.name
            };
            return (suppliersList = addEquipmentToSuppliersList(
              suppliersList,
              equipmentCopy,
              equipmentCopy.supplier
            ));
          });
      }
    }

    return suppliersList;
  };

  const handleShowAllSuppliersEquipment = () => {
    if (equipmentRows.length > 0) {
      setEquipmentRows([]);
    } else {
      setEquipmentRows([...Object.keys(suppliersRows)]);
    }
  };

  const handleShowSuppliersEquipment = (supplier) => {
    return setEquipmentRows(
      equipmentRows.includes(supplier)
        ? equipmentRows.filter(
            (currentSupplier) => currentSupplier !== supplier
          )
        : equipmentRows.concat(supplier),
      [...equipmentRows]
    );
  };
  //endregion

  //#region [render]
  if (!suppliersRows) return;
  return (
    <div className='custom-table-wrapper'>
      <table className='equipment-list-table'>
        <thead>
          <tr>
            <th colSpan={4} />
            <th>{i18n._('config.equipmentList.hydraulicEquipment.accenta')}</th>
            <th colSpan={4}>
              {i18n._('config.equipmentList.modules.manufacturing')}
            </th>
          </tr>
          <tr>
            <th>
              <FontAwesomeIcon
                className='equipment-list-chevron'
                icon={equipmentRows.length > 0 ? faChevronDown : faChevronRight}
                onClick={handleShowAllSuppliersEquipment}
              />
            </th>
            <th>{i18n._('config.equipmentList.supplierAndequipment')}</th>
            <th>Module lié</th>
            <th>{i18n._('config.equipmentList.total')}</th>
            <th>{i18n._('config.equipmentList.total')}</th>
            <th>{i18n._('config.equipmentList.hydraulicAndStructure')}</th>
            <th>{i18n._('config.equipmentList.factoryInsulation')}</th>
            <th>{i18n._('config.equipmentList.siteInsulation')}</th>
            <th>{i18n._('config.equipmentList.config')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(suppliersRows).map((supplier, index) => {
            // fournisseur Hydrotherm (modules) : les prix sont détaillées
            if (supplier === SUPPLIER.HYDROTHERM) {
              const { equipment, supplierTotalPrices } =
                suppliersRows[supplier];
              const {
                total,
                moduleEquipmentTotal,
                hydraulicAndStructure,
                factoryInsulation,
                siteInsulation,
                equipmentConfig
              } = supplierTotalPrices;
              return (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        className='equipment-list-chevron'
                        icon={
                          equipmentRows.includes(supplier)
                            ? faChevronDown
                            : faChevronRight
                        }
                        onClick={() => handleShowSuppliersEquipment(supplier)}
                      />
                    </td>
                    <td className='bold'>{supplier}</td>
                    <td />
                    <td className='bold'>
                      {getTableValue(i18n, total, UNIT.EURO, 0)}
                    </td>
                    <td />
                    <td>
                      {getTableValue(i18n, hydraulicAndStructure, UNIT.EURO, 0)}
                    </td>
                    <td>
                      {getTableValue(i18n, factoryInsulation, UNIT.EURO, 0)}
                    </td>
                    <td>{getTableValue(i18n, siteInsulation, UNIT.EURO, 0)}</td>
                    <td>
                      {getTableValue(i18n, equipmentConfig, UNIT.EURO, 0)}
                    </td>
                  </tr>
                  {equipmentRows.includes(supplier) && (
                    <EquipmentRows rows={equipment} modulesRows={true} />
                  )}
                </Fragment>
              );
            } else {
              // autre fournisseur : les prix sont simplifiés
              const { equipment, supplierTotalPrice } = suppliersRows[supplier];
              return (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        className='equipment-list-chevron'
                        icon={
                          equipmentRows.includes(supplier)
                            ? faChevronDown
                            : faChevronRight
                        }
                        onClick={() => handleShowSuppliersEquipment(supplier)}
                      />
                    </td>
                    <td className='bold'>{supplier}</td>
                    <td />
                    <td className='bold'>
                      {getTableValue(i18n, supplierTotalPrice, UNIT.EURO, 0)}
                    </td>
                    <td>
                      {getTableValue(i18n, supplierTotalPrice, UNIT.EURO, 0)}
                    </td>
                    <td colSpan={4} />
                  </tr>
                  {equipmentRows.includes(supplier) && (
                    <EquipmentRows rows={equipment} />
                  )}
                </Fragment>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
  //endregion
};
export default SortBySupplierList;
