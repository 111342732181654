import { useLingui } from '@lingui/react';
import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import './HeadlossModal.css';

const HeadlossModal = ({ isOpen, onClose, matchingEquipment }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  return (
    <Modal show={isOpen} onHide={onClose} centered className='headloss-modal'>
      <Modal.Header closeButton>
        <div className='headloss-modal-header'>
          <h1>{i18n._('headlossModal.title')}</h1>
          {i18n._('headlossModal.description')}
        </div>
      </Modal.Header>
      <Modal.Body className='headloss-modal-body'>
        <h3>{i18n._('headlossModal.subtitle')}</h3>
        <div className='headloss-modal-table'>
          <Table striped>
            <thead>
              <tr>
                <th>{i18n._('headlossModal.table.name')}</th>
                <th>{i18n._('headlossModal.table.length')}</th>
                <th>{i18n._('headlossModal.table.diameter')}</th>
              </tr>
            </thead>
            <tbody>
              {matchingEquipment?.length ? (
                matchingEquipment?.map((equipement) => (
                  <tr key={equipement.id}>
                    <td>{equipement.name}</td>
                    <td>{equipement.length}</td>
                    <td>{equipement.diameter}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={3}
                    className='text-center fst-italic text-secondary'
                  >
                    {i18n._('headlossModal.table.noData')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HeadlossModal;
