// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-select {
  width: 100%;
  max-width: 1210px;
  margin-top: 15px;
}

.group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 7px 5px 7px;
}

.group-header button {
  font-size: 13px;
  padding: 3px 5px;
}

.group-header button svg {
  margin-right: 5px;
}

.result-option {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.result-option-label {
  font-size: 15px;
  user-select: none;
}

.results-select-tags {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ResultSelect/ResultSelect.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB","sourcesContent":[".results-select {\n  width: 100%;\n  max-width: 1210px;\n  margin-top: 15px;\n}\n\n.group-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 0 7px 5px 7px;\n}\n\n.group-header button {\n  font-size: 13px;\n  padding: 3px 5px;\n}\n\n.group-header button svg {\n  margin-right: 5px;\n}\n\n.result-option {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  width: 100%;\n  cursor: pointer;\n  user-select: none;\n}\n\n.result-option-label {\n  font-size: 15px;\n  user-select: none;\n}\n\n.results-select-tags {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
