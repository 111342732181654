// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-form-page {
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
}

.config-form-page .bloc {
    border-bottom: none;
}

.config-form-opaque-layer {
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/components/ConfigFormPage/ConfigFormPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".config-form-page {\n    padding: 0 15px 15px 15px;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    background-color: white;\n    overflow-y: auto;\n}\n\n.config-form-page .bloc {\n    border-bottom: none;\n}\n\n.config-form-opaque-layer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
