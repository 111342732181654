// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-services {
    display: flex;
    gap: 80px;
    margin-top: 15px;
}

.settings-noNeeds {
    font-size: 14px;
    color: var(--danger-color);
    font-weight: 500;
    margin-top: 10px;
}

.settings-row {
    display: flex;
    gap: 50px;
    margin-top: 15px;
}

.settings-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.settings-distrib-inputs {
    display: flex;
    gap: 15px;
    margin-top: -15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/buildingDescription/services/settings/SettingsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".settings-services {\n    display: flex;\n    gap: 80px;\n    margin-top: 15px;\n}\n\n.settings-noNeeds {\n    font-size: 14px;\n    color: var(--danger-color);\n    font-weight: 500;\n    margin-top: 10px;\n}\n\n.settings-row {\n    display: flex;\n    gap: 50px;\n    margin-top: 15px;\n}\n\n.settings-column {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.settings-distrib-inputs {\n    display: flex;\n    gap: 15px;\n    margin-top: -15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
