// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-page {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.version-page span {
    font-size: 19px;
}`, "",{"version":3,"sources":["webpack://./src/pages/version/VersionPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".version-page {\n    padding: 15px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.version-page span {\n    font-size: 19px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
