// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculation-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.calculation-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.calculation-form .opaque-layer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculation-form .opaque-layer .alert {
  display: flex;
  align-items: center;
  gap: 10px;
}

.calculation-form-wrapper > .alert {
  margin-bottom: 0;
}

.calculation-form-btns {
  display: flex;
  gap: 20px;
  width: 66.6%;
}

.calculation-form-btn {
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/components/CalculationForm/CalculationForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,OAAO;AACT","sourcesContent":[".calculation-form-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n}\n\n.calculation-form {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.calculation-form .opaque-layer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.calculation-form .opaque-layer .alert {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.calculation-form-wrapper > .alert {\n  margin-bottom: 0;\n}\n\n.calculation-form-btns {\n  display: flex;\n  gap: 20px;\n  width: 66.6%;\n}\n\n.calculation-form-btn {\n  font-size: 14px;\n  height: 45px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
