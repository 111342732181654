// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-sidebar {
  position: fixed;
  top: 310px;
  left: 0;
  height: 380px;
  z-index: 102;
  background-color: white;
  padding: 10px 0;
  border-radius: 5px;
  border: 1px solid var(--light-grey);
}

.results-sidebar-arrow {
  color: var(--primary-color);
  position: absolute;
  top: -11px;
  right: -8px;
  font-size: 19px;
  cursor: pointer;
  background: white;
  border-radius: 100%;
}

.results-sidebar-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.results-sidebar .nav-link {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0.32rem 0.8rem;
  margin: 0 3px;
}

.results-sidebar .nav-link img {
  width: 19px;
  height: 19px;
}

.results-sidebar .nav-link span {
  color: black;
  font-size: 16px;
}

.results-sidebar .nav-link.active {
  background-color: var(--primary-color);
}

.results-sidebar .nav-link.active span {
  color: white;
  font-weight: 400;
}

.results-sidebar .nav-link:not(.active):hover {
  background-color: var(--light-grey);
}

.results-sidebar-nav.nav-closed {
  width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,OAAO;EACP,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,WAAW;AACb","sourcesContent":[".results-sidebar {\n  position: fixed;\n  top: 310px;\n  left: 0;\n  height: 380px;\n  z-index: 102;\n  background-color: white;\n  padding: 10px 0;\n  border-radius: 5px;\n  border: 1px solid var(--light-grey);\n}\n\n.results-sidebar-arrow {\n  color: var(--primary-color);\n  position: absolute;\n  top: -11px;\n  right: -8px;\n  font-size: 19px;\n  cursor: pointer;\n  background: white;\n  border-radius: 100%;\n}\n\n.results-sidebar-nav {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.results-sidebar .nav-link {\n  border-radius: 5px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n  padding: 0.32rem 0.8rem;\n  margin: 0 3px;\n}\n\n.results-sidebar .nav-link img {\n  width: 19px;\n  height: 19px;\n}\n\n.results-sidebar .nav-link span {\n  color: black;\n  font-size: 16px;\n}\n\n.results-sidebar .nav-link.active {\n  background-color: var(--primary-color);\n}\n\n.results-sidebar .nav-link.active span {\n  color: white;\n  font-weight: 400;\n}\n\n.results-sidebar .nav-link:not(.active):hover {\n  background-color: var(--light-grey);\n}\n\n.results-sidebar-nav.nav-closed {\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
