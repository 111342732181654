// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configs-header {
  position: sticky;
  top: 67px;
  margin-bottom: 15px;
  z-index: 300;
  display: flex;
  align-items: center;
  width: fit-content;
}

.configs-header-select {
  width: 600px;
  margin-right: 15px;
}



.configs-header-select > .menu {
  display: flex;
}

.config-header-click-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.configs-header-select .config-header-select-option {
  display: flex;
}

.configs-header-select .config-header-select-option-label {
  flex-grow: 1;
}

.configs-header-delete-icon {
  font-size: 18px;
  color: var(--danger-color);
  cursor: pointer;
  margin: 0 8px 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;;;AAIA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".configs-header {\n  position: sticky;\n  top: 67px;\n  margin-bottom: 15px;\n  z-index: 300;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n}\n\n.configs-header-select {\n  width: 600px;\n  margin-right: 15px;\n}\n\n\n\n.configs-header-select > .menu {\n  display: flex;\n}\n\n.config-header-click-area {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.configs-header-select .config-header-select-option {\n  display: flex;\n}\n\n.configs-header-select .config-header-select-option-label {\n  flex-grow: 1;\n}\n\n.configs-header-delete-icon {\n  font-size: 18px;\n  color: var(--danger-color);\n  cursor: pointer;\n  margin: 0 8px 0 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
