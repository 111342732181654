// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-section-exchange-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.src-section-exchange-container>span {
    font-size: 15px;
}

.src-section-exchange-container>span>svg {
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/geostorage/settings/sections/SourceSection/SourceSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".src-section-exchange-container {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n\n.src-section-exchange-container>span {\n    font-size: 15px;\n}\n\n.src-section-exchange-container>span>svg {\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
