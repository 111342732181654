import { useLingui } from '@lingui/react';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { UNIT } from '../../../../../../../../server/constants';
import { fetchPumpOperatingCurves } from '../../../../../../api/configSst.api';
import ErrorBadge from '../../../../../../components/ErrorBadge/ErrorBadge';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import {
  formatValueWithUnit,
  isArrNullOrEmpty,
  isNull,
  roundUp
} from '../../../../../../utils/data.utils';
import { getSortedExchangers } from '../../../../../../utils/heatpump.utils';

const HeadLossTotalSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [selectedPumps, setSelectedPumps] = useState([]);
  const [isLoadingCurve, setIsLoadingCurve] = useState();
  //endregion

  //#region [refs]
  const bottomAutoScrollRef = useRef(null);
  //endregion

  //#region [effects]
  useEffect(() => {
    setTimeout(() => {
      bottomAutoScrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  }, [selectedPumps]);
  //endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('notAvailable');

  const getPumpOperatingCurves = async (exchanger) => {
    try {
      if (!isArrNullOrEmpty(selectedPumps)) setSelectedPumps([]);
      const exchangers = exchangersHeadLossData.filter(
        (e) =>
          e.exchangerType === exchanger.exchangerType &&
          e.hpPosition === exchanger.hpPosition
      );
      setSelectedPumps([...exchangers]);
      setIsLoadingCurve(true);
      const { ConfigSstID } = config.ConfigsSst[0];
      const exchangersWithCurves = await fetchPumpOperatingCurves(
        ConfigSstID,
        exchangers
      );
      setSelectedPumps([...exchangersWithCurves]);
    } catch (err) {
      openErrorToast(err);
      setSelectedPumps([]);
    } finally {
      setIsLoadingCurve(false);
    }
  };

  const getPumpRefOrError = (exchanger, hp) => {
    if (exchanger.pumpsRefs?.length === 1 && !isNull(exchanger.pumpsRefs[0])) {
      // pas d'erreur et une seule référence, on l'affiche
      return {
        error: null,
        content: exchanger.pumpsRefs[0]
      };
    } else if (!exchanger.pumpsRefs || !hp.lowerModule) {
      // pas de tableau, le module bas n'a pas été trouvé
      return {
        error: true,
        content: i18n._('config.pumps.headLoss.pumpRef.noLowerModule')
      };
    } else if (
      !hp.lowerModule?.version &&
      isArrNullOrEmpty(hp.lowerModule?.equipment)
    ) {
      // le module bas n'a pas été conçu
      return {
        error: true,
        content: i18n._('config.hps.lowerModule.notDesigned', {
          lowerModule: hp.lowerModule?.name
        })
      };
    } else if (
      isArrNullOrEmpty(exchanger.pumpsRefs) ||
      exchanger.pumpsRefs.every((pump) => isNull(pump))
    ) {
      // tableau vide, aucune référence de pompe n'a été trouvé
      return {
        error: true,
        content: i18n._('config.pumps.headLoss.pumpRef.notFound', {
          exchangerName: exchanger.exchangerType + exchanger.hpPosition
        })
      };
    } else if (exchanger.pumpsRefs?.length > 1) {
      // plusieurs pompes différentes trouvées, erreur
      return {
        error: true,
        content: i18n._('config.pumps.headLoss.pumpRef.multipleRefs', {
          exchangerName: exchanger.exchangerType + exchanger.hpPosition
        })
      };
    }
  };
  //#endregion

  //#region [render]
  const { heatpumps, pumps } = config.ConfigsSst[0].Data;
  const { exchangersHeadLossData } = pumps;
  const sortedExchangers = getSortedExchangers(exchangersHeadLossData);
  const operatingCurveTitle = !isArrNullOrEmpty(selectedPumps)
    ? `${selectedPumps[0].exchangerType}${selectedPumps[0].hpPosition} - ${selectedPumps[0].pumpsRefs[0]}`
    : undefined;
  return (
    <Section
      title={i18n._('config.pumps.headLoss.flowRateHeadLossPair')}
      level={2}
      open
    >
      <table className='custom-table'>
        <thead>
          <tr>
            <th>{i18n._('config.pumps.headLoss.pumps')}</th>
            <th>{i18n._('config.pumps.headLoss.hp')}</th>
            <th>{i18n._('config.pumps.headLoss.need')}</th>
            <th>{i18n._('config.pumps.headLoss.connectedTo')}</th>
            <th>{i18n._('config.pumps.headLoss.fluid')}</th>
            <th>{i18n._('config.pumps.headLoss.flowRate')}</th>
            <th>{i18n._('config.pumps.headLoss.total')}</th>
            {config.IsModular && (
              <th>{i18n._('config.pumps.headLoss.pumpRef')}</th>
            )}

            {config.IsModular && (
              <th>{i18n._('config.pumps.headLoss.operatingCurves')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedExchangers.map((exchanger, index) => {
            const hp = heatpumps.list.find(
              (hp) => hp.position === exchanger.hpPosition
            );
            const pumpReference = getPumpRefOrError(exchanger, hp);
            return (
              <tr key={'exchanger_destinations_headloss' + index}>
                <td>
                  {i18n._(
                    `hydraulicConfig.table.td.${exchanger.exchangerType}`,
                    {
                      position: exchanger.hpPosition
                    }
                  )}
                </td>
                <td>{exchanger.hpReference}</td>
                <td>{i18n._(`need.${exchanger.hpNeed}`)}</td>
                <td>
                  {i18n._(
                    `heatpump.operatingPointsSource.${exchanger.hpOperatingPointsSource}`
                  )}
                </td>
                <td>{i18n._(`pumpFluid.${exchanger.exchangerFluid}`)}</td>
                <td className='col-flowRate'>
                  {formatValue(
                    exchanger.exchangerFlowRate,
                    UNIT.CUBIC_METER_PER_HOUR,
                    1
                  )}
                </td>
                <td className='col-headLoss'>
                  {formatValue(
                    roundUp(exchanger.headLossTotal, 1),
                    UNIT.WATER_COLUMN_METER,
                    1
                  )}
                </td>
                {config.IsModular && (
                  <td>
                    {pumpReference.error ? (
                      <ErrorBadge
                        tooltipContent={pumpReference.content}
                        tooltipId={
                          index + exchanger.exchangerType + exchanger.hpPosition
                        }
                        portalContainer={document.body}
                      >
                        {i18n._('error')}
                      </ErrorBadge>
                    ) : (
                      pumpReference.content
                    )}
                  </td>
                )}
                {config.IsModular && (
                  <td className='operatingCurve-button'>
                    {pumpReference.error ? (
                      <ErrorBadge
                        tooltipContent={i18n._(
                          'config.pumps.headLoss.curveButton.error',
                          {
                            exchangerName:
                              exchanger.exchangerType + exchanger.hpPosition
                          }
                        )}
                        tooltipId={
                          index + exchanger.exchangerType + exchanger.hpPosition
                        }
                        portalContainer={document.body}
                      >
                        {i18n._('error')}
                      </ErrorBadge>
                    ) : (
                      <Form.Check
                        type='radio'
                        checked={selectedPumps.some(
                          (pump) =>
                            pump.exchangerType === exchanger.exchangerType &&
                            pump.hpPosition === exchanger.hpPosition
                        )}
                        onChange={async () => getPumpOperatingCurves(exchanger)}
                        disabled={isLoadingCurve}
                        id={`exchangerName_${index}`}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pumps-calculations-operatingCurves-section'>
        {operatingCurveTitle && (
          <Fragment>
            <h3>
              {i18n._('config.pumps.headloss.calculations.operatingCurve')}
            </h3>
            <p className='pumps-calculations-operatingCurves-pumpName'>
              {operatingCurveTitle}
            </p>
          </Fragment>
        )}
        <div className='pumps-calculations-operatingCurves-wrapper'>
          <div className='pumps-calculations-operatingCurves-container'>
            {selectedPumps.map((pump, index) => {
              return (
                <Fragment key={'pump' + '' + index}>
                  {isLoadingCurve ? (
                    <div className='pumps-calculations-operatingCurves-spinner'>
                      <Spinner />
                    </div>
                  ) : (
                    <div className='pumps-calculations-operatingCurve'>
                      <img src={pump.curveUrl} />
                      <p>
                        {i18n._(
                          'config.pumps.headloss.calculations.operatingCurve.subtitle',
                          {
                            need: i18n
                              ._(`need.${pump.hpNeed}`)
                              .toLocaleLowerCase(),
                            operatingPointsSource: i18n._(
                              `heatpump.operatingPointsSource.${pump.hpOperatingPointsSource}`
                            )
                          }
                        )}
                      </p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
        {!isArrNullOrEmpty(selectedPumps) && <div ref={bottomAutoScrollRef} />}
      </div>
    </Section>
  );
  //#endregion
};

export default HeadLossTotalSection;
