// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-header {
  margin-bottom: 20px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.project-header .card-content {
  display: flex;
  align-items: center;
  border: none;
}

.project-header img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 5px;
  margin-right: 15px;
}

.project-header-name {
  font-size: 23px;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: -3px;
}

.project-header-name .fa-pen-to-square {
  color: black;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 2px;
}

.project-header-badges {
  margin-bottom: 3px;
}

.project-header .badge {
  font-size: 10px;
}

.project-header .badge:not(:first-of-type) {
  margin-left: 10px;
}

.project-header .badge:first-of-type {
  background-color: #7891b3 !important;
}

.project-header .badge:last-of-type {
  background-color: #81aff2 !important;
}

.project-header .nav-pills {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/project/components/ProjectHeader/ProjectHeader.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,sDAAsD;EACtD,8CAA8C;AAChD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".project-header {\n  margin-bottom: 20px;\n  background-color: white;\n  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);\n  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);\n}\n\n.project-header .card-content {\n  display: flex;\n  align-items: center;\n  border: none;\n}\n\n.project-header img {\n  width: 50px;\n  height: 50px;\n  min-width: 50px;\n  border-radius: 5px;\n  margin-right: 15px;\n}\n\n.project-header-name {\n  font-size: 23px;\n  font-weight: 600;\n  color: black;\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  margin-bottom: -3px;\n}\n\n.project-header-name .fa-pen-to-square {\n  color: black;\n  cursor: pointer;\n  font-size: 18px;\n  margin-bottom: 2px;\n}\n\n.project-header-badges {\n  margin-bottom: 3px;\n}\n\n.project-header .badge {\n  font-size: 10px;\n}\n\n.project-header .badge:not(:first-of-type) {\n  margin-left: 10px;\n}\n\n.project-header .badge:first-of-type {\n  background-color: #7891b3 !important;\n}\n\n.project-header .badge:last-of-type {\n  background-color: #81aff2 !important;\n}\n\n.project-header .nav-pills {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-left: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
