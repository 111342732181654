// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.chart>p {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 500;
    white-space: pre-wrap;
    text-align: center;
    width: 90%;
}

.js-plotly-plot .plotly .modebar {
    right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Chart/Chart.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":[".chart {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 10px;\n}\n\n.chart>p {\n    font-size: 15px;\n    margin-top: 5px;\n    font-weight: 500;\n    white-space: pre-wrap;\n    text-align: center;\n    width: 90%;\n}\n\n.js-plotly-plot .plotly .modebar {\n    right: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
