// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculation-desc {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.calculation-desc:first-of-type {
    margin: 15px 0;
}

.calculation-desc:not(:first-of-type) {
    margin: 30px 0;
}

.calculation-desc-title {
    color: rgb(109, 109, 109);
    font-size: 16px;
    font-weight: 500;
}

.calculation-btns {
    display: flex;
    align-items: center;
    gap: 20px;
}

.calculation-btns button,
.calculation-btns a {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 45px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputeForm/components/CalculationCard/CalculationCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;;IAEI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,YAAY;AAChB","sourcesContent":[".calculation-desc {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.calculation-desc:first-of-type {\n    margin: 15px 0;\n}\n\n.calculation-desc:not(:first-of-type) {\n    margin: 30px 0;\n}\n\n.calculation-desc-title {\n    color: rgb(109, 109, 109);\n    font-size: 16px;\n    font-weight: 500;\n}\n\n.calculation-btns {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n.calculation-btns button,\n.calculation-btns a {\n    font-size: 15px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n    height: 45px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
