// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-badge {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    font-weight: 500;
    color: var(--danger-color);
}

.error-badge svg {
    color: var(--danger-color);
    font-size: 15px;
    outline-color: var(--danger-color);
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorBadge/ErrorBadge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,kCAAkC;AACtC","sourcesContent":[".error-badge {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--danger-color);\n}\n\n.error-badge svg {\n    color: var(--danger-color);\n    font-size: 15px;\n    outline-color: var(--danger-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
