// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 60%;
  min-width: 700px;
}

.general-form textarea {
  width: 600px;
}

.general-radio-btns > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.general-surface-input {
  display: flex;
  align-items: center;
  gap: 5px;
}

.general-next-btn > svg {
  margin-left: 7px;
}

.general-remaining {
  width: 600px;
  font-size: 13px;
  color: rgb(117, 117, 117);
  margin-top: -10px;
  text-align: end;
}

.general-upload-img-wrapper {
  display: flex;
  align-items: center;
}

.general-upload-img-wrapper img {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  margin-left: 15px;
}

.general-form .param-input-body {
  max-width: 350px;
  min-width: 200px;
  width: 25vw;
}

.general-form .col {
  padding-left: 0;
}

@media (max-width: 1007px) {
  .general-form {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/GeneralForm/GeneralForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".general-form {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  width: 60%;\n  min-width: 700px;\n}\n\n.general-form textarea {\n  width: 600px;\n}\n\n.general-radio-btns > div {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n\n.general-surface-input {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.general-next-btn > svg {\n  margin-left: 7px;\n}\n\n.general-remaining {\n  width: 600px;\n  font-size: 13px;\n  color: rgb(117, 117, 117);\n  margin-top: -10px;\n  text-align: end;\n}\n\n.general-upload-img-wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.general-upload-img-wrapper img {\n  width: 38px;\n  height: 38px;\n  border-radius: 5px;\n  margin-left: 15px;\n}\n\n.general-form .param-input-body {\n  max-width: 350px;\n  min-width: 200px;\n  width: 25vw;\n}\n\n.general-form .col {\n  padding-left: 0;\n}\n\n@media (max-width: 1007px) {\n  .general-form {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
