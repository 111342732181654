// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headloss-modal .modal-header {
  align-items: flex-start;
}

.headloss-modal .modal-header button {
  margin-top: 10px;
}

.headloss-modal .modal-body .headloss-modal-table {
  height: calc(41px * 10 + 1px);
  overflow-y: auto;
  scrollbar-width: thin;
}
.headloss-modal .modal-body .headloss-modal-table table {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeadlossModal/HeadlossModal.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".headloss-modal .modal-header {\n  align-items: flex-start;\n}\n\n.headloss-modal .modal-header button {\n  margin-top: 10px;\n}\n\n.headloss-modal .modal-body .headloss-modal-table {\n  height: calc(41px * 10 + 1px);\n  overflow-y: auto;\n  scrollbar-width: thin;\n}\n.headloss-modal .modal-body .headloss-modal-table table {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
