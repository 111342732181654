import {
  COLD_MANAGEMENT,
  HP_TYPE,
  LOWER_MODULES,
  NEED,
  UPPER_TAPPING_ND
} from '../../../server/constants';
import { hasColdAndHeatOrDhw, hasColdOnly } from './heatpump.utils';

const formatEquipmentDetails = (equipment) => {
  // on récupère les informations du module en bdd
  const newObject = {};
  Object.entries(equipment).forEach(([key, value]) => {
    const formatedKey = key.charAt(0).toLowerCase() + key.slice(1);
    return (newObject[formatedKey] = value);
  });
  return newObject;
};

//#region [PACs]
export const getHpLowerModuleKey = (hp) => {
  const { coldManagement } = hp.pipingEquipment;
  const supplierReference = hp.supplier.slice(0, 3);
  let moduleKey;
  if (hp.type === HP_TYPE.GEO) {
    moduleKey = `M-Hpg-${supplierReference}-A`;
  } else {
    if (!hp.needs.includes(NEED.COLD)) {
      moduleKey = LOWER_MODULES.M_HPA_C_A;
    } else if (hasColdOnly(hp) && coldManagement === COLD_MANAGEMENT.ABSENT) {
      moduleKey = LOWER_MODULES.M_HPA_C_FEE;
    } else if (
      hasColdAndHeatOrDhw(hp) &&
      coldManagement === COLD_MANAGEMENT.COLD_SIDE
    ) {
      moduleKey = LOWER_MODULES.M_HPA_C_RCE;
    } else if (
      hasColdAndHeatOrDhw(hp) &&
      coldManagement === COLD_MANAGEMENT.HEAT_SIDE
    ) {
      moduleKey = LOWER_MODULES.M_HPA_C_REE;
    } else if (
      hasColdAndHeatOrDhw(hp) &&
      coldManagement === COLD_MANAGEMENT.COLD_WATER_TANK
    ) {
      moduleKey = LOWER_MODULES.M_HPA_C_RDI;
    }
  }

  return moduleKey;
};

export const getHpLowerModuleSettings = (hp, selectedModule) => {
  if (!selectedModule) return;
  const newLowerModule = formatEquipmentDetails(selectedModule);
  const moduleName = {
    [HP_TYPE.GEO]: `${newLowerModule.module}-DN${newLowerModule.nominalDiameter}`,
    [HP_TYPE.AERO]: `${newLowerModule.module}-DN${newLowerModule.nominalDiameter}`
  };
  newLowerModule.name = moduleName[hp.type];
  return newLowerModule;
};

export const getHpUpperModuleName = (hp, services) => {
  const width = hp.lowerModule?.width ?? 1200; // TODO 1200 est une largeur par défaut temporaire
  const evapNd = services.upperModules.evaporator.nominalDiameter;
  const condNd = services.upperModules.condensor.nominalDiameter;
  const vdc = hp.collectorsEquipment.Vdc ? '-Vdc' : '';
  const vde = hp.collectorsEquipment.Vde ? '-Vde' : '';
  const { upperEvapTapping, upperCondTapping } = hp.collectorsEquipment;
  const phexx =
    upperEvapTapping !== UPPER_TAPPING_ND.ABSENT
      ? `-PhE${upperEvapTapping}`
      : '';
  const phcxx =
    upperCondTapping !== UPPER_TAPPING_ND.ABSENT
      ? `-PhC${upperCondTapping}`
      : '';
  return `COL-A-${
    width + 500
  }-E${evapNd}C${condNd}${vdc}${vde}${phexx}${phcxx}`;
};
//#endregion
